<template>
  <b-card>
    <b-card-header>
      <b-card-title>Registered users by country</b-card-title>

      <b-form-group class="my-1 ml-auto mr-1">
        <h5>Search by country</h5>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Search by country"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group class="mb-1 mt-1 mx-md-1 mx-sx-auto align-bottom">
        <h5>Select type</h5>
        <b-button-toolbar>
          <b-button-group>
            <b-button
              variant="primary"
              :disabled="!useAffKeys"
              @click.prevent="onSelect"
            >
              ALL
            </b-button>
            <b-button
              variant="secondary"
              :disabled="useAffKeys"
              @click.prevent="onSelect"
            >
              Affiliates GA, FA, TW
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-form-group>

      <b-form-group class="mt-1">
        <h5>Date range</h5>
        <div class="d-flex">
          <flat-pickr
            v-model="dateRange"
            class="form-control"
            :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
          />

          <b-button
            variant="outline-primary"
            :disabled="dateRange == null"
            @click.prevent="onSearch"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
      </b-form-group>
    </b-card-header>
    <b-card-body>
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive
        small
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isLoading"
        :sort-by="'total_registered'"
        :sort-desc="true"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <table-loading />
        </template>
      </b-table>
      <pagination-row />
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BFormGroup, BTable,
  BInputGroup, BFormInput, BInputGroupAppend, BButtonToolbar, BButtonGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import table from '@/mixins/table'
import StatisticsAPI from '@/api/statistics'

export default {
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButtonToolbar,
    BButtonGroup,
    flatPickr,
  },
  mixins: [table],
  data() {
    return {
      fields: [
        {
          key: 'country_name',
          label: 'country',
          sortable: true,
        },
        {
          key: 'total_registered',
          sortable: true,
        },
        {
          key: 'with_trial',
          sortable: true,
        },
        {
          key: 'paid_account',
          sortable: true,
        },
      ],
      dateRange: null,
      useAffKeys: false,
      items: [],
      isLoading: false,
      filterOn: ['country_name'],
      filter: null,
    }
  },
  watch: {
    items() {
      this.totalRows = this.items.length
    },
  },
  created() {
    this.totalRows = this.items.length
  },
  beforeMount() {
    const now = new Date()
    const back7 = new Date()

    back7.setDate(back7.getDate() - 7)

    this.dateRange = `${back7.toISOString().slice(0, 10)} to ${now.toISOString().slice(0, 10)}`
    this.onSearch()
  },
  methods: {
    onSelect() {
      if (this.dateRange === null) return

      this.useAffKeys = !this.useAffKeys

      this.onSearch()
    },
    onSearch() {
      this.isLoading = true
      const date = this.dateRange.split(' to ')
      const from = date[0].concat(' 00:00:00')
      const to = date[1].concat(' 23:59:59')
      StatisticsAPI.getRegisteredUsersByCountry(from, to, this.useAffKeys)
        .then(response => {
          this.items = response
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
@import "../../../../@core/scss/vue/libs/vue-flatpicker";
</style>
