<template>
  <b-overlay :show="loading">
    <b-row class="match-height">
      <!--  left column   -->
      <b-col
        xl="5"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <registered-users-table />
          </b-col>
        </b-row>
      </b-col>
      <!--  right column    -->
      <b-col
        xl="7"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <registration-chart />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import {
  BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import RegisteredUsersTable from '@/views/statistics/marketing-statistics/components/RegisteredUsersTable.vue'
import RegistrationChart from '@/views/statistics/marketing-statistics/components/RegistrationChart.vue'

export default {
  components: {
    RegistrationChart,
    RegisteredUsersTable,
    BOverlay,
    BRow,
    BCol,
  },
  data() {
    return {
      loading: false,
    }
  },
}
</script>
