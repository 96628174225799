<template>
  <b-overlay
    :show="isLoading"
    :variant="skin"
  >
    <b-card
      no-body
    >
      <b-card-header class="align-items-baseline">
        <b-card-title class="w-50">
          Registrations overview
        </b-card-title>
        <date-range-selector
          @select="updateData"
        />
        <b-button-toolbar>
          <b-button-group>
            <b-button
              variant="primary"
              @click.prevent="filter=['organic', 'all_aff']"
            >
              AFF vs Organic
            </b-button>
            <b-button
              variant="secondary"
              @click.prevent="filter=['e8_aff_ga', 'e8_aff_tw','e8_aff_fb', 'other_aff']"
            >
              Aff details
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-card-header>
      <b-card-body class="pb-0 position-relative">
        <vue-apex-charts
          v-if="!isLoading"
          ref="chart"
          height="500px"
          :options="balanceChart.chartOptions"
          :series="balanceChart.series"
        />
      </b-card-body>
    </b-card>

  </b-overlay>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, VBPopover, BOverlay, BButton, BButtonGroup, BButtonToolbar,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { get } from 'vuex-pathify'
import { formatDate } from '@/plugins/formaters'
import DateRangeSelector from '@/components/page/orders/DateRangeSelector.vue'
import statisticsAPI from '@/api/statistics'

export default {
  components: {
    DateRangeSelector,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BButtonGroup,
    BButtonToolbar,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      isLoading: false,
      selection: 'all',
      registrations: null,
      filter: ['organic', 'all_aff'],
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    getSeries() {
      const series = []
      const x = new Set()
      if (this.registrations === null) {
        return {
          series,
          x,
        }
      }

      const registrations = Object.fromEntries(Object.entries(this.registrations).filter(([key]) => this.filter.includes(key)))

      // find starting point
      const starts = []
      Object.values(registrations).forEach(value => {
        if (value.length === 0) return
        starts.push(value.reduce((previous, current) => (((Number(current.week) > Number(previous.week) && Number(current.year) === Number(previous.year)) || Number(current.year) < Number(previous.year)) ? current : previous)))
      })

      const start = starts.reduce((previous, current) => (((Number(current.week) > Number(previous.week) && Number(current.year) === Number(previous.year)) || Number(current.year) < Number(previous.year)) ? current : previous))

      // find last point
      const ends = []

      Object.values(registrations).forEach(value => {
        if (value.length === 0) return
        ends.push(value.reduce((previous, current) => ((Number(current.week) < Number(previous.week) && Number(current.year) === Number(previous.year)) || Number(current.year) < Number(previous.year) ? previous : current)))
      })

      const end = ends.reduce((previous, current) => ((Number(current.week) < Number(previous.week) && Number(current.year) === Number(previous.year)) || Number(current.year) < Number(previous.year) ? previous : current))

      const sWeek = Number(start.week)
      const sYear = Number(start.year)
      const eWeek = Number(end.week)
      const eYear = Number(end.year)

      Object.entries(registrations).forEach(o => {
        const key = o[0]
        const value = o[1]
        const s = []

        let iWeek = sWeek
        let iYear = sYear

        for (; (iWeek !== eWeek + 2 || iYear !== eYear); iWeek++) {
          if (iWeek > 52) {
            iYear += 1
            iWeek = 0
          }
          // eslint-disable-next-line no-loop-func
          const week = value.find(v => Number(v.week) === iWeek && Number(v.year) === iYear)

          if (iWeek === 0) {
            s[s.length - 1] += typeof week === 'undefined' ? 0 : week.registered
          } else if (typeof week === 'undefined') s.push(0)
          else s.push(week.registered)

          x.add(this.getDateRangeOfWeek(iWeek, iYear))
        }

        series.push({ name: key, data: s })
      })

      return {
        series,
        x,
      }
    },
    balanceChart() {
      return {
        series: this.getSeries.series,
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            zoom: {
              enabled: true,
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top',
              },
              columnWidth: '40%',
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [this.getSeries.series.length - 1],
            formatter(_val, opt) {
              const { series } = opt.w.config
              const idx = opt.dataPointIndex
              const total = series.reduce((total, self) => total + self.data[idx], 0)
              return `${total}`
            },
            style: {
              colors: ['#fff'],
            },
          },
          colors: [$themeColors.primary, $themeColors.secondary, $themeColors.success, $themeColors.info, $themeColors.warning, $themeColors.danger],
          labels: Array.from(this.getSeries.x),
          xaxis: {
            type: 'category',
            tickPlacement: 'on',
            labels: {
              hideOverlappingLabels: true,
              trim: false,
              style: {
                colors: '#b9b9c3',
              },
            },
            axisBorder: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
              },
            },
          },
          stroke: {
            show: false,
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40,
            labels: {
              colors: '#b9b9c3',
            },
          },
          tooltip: {
            x: { show: true },
            y: { show: true },
          },
          fill: {
            opacity: 0.5,
          },
        },
      }
    },
  },
  beforeMount() {
    this.isLoading = true
    statisticsAPI.getWeeklyRegistrations()
      .then(response => {
        this.registrations = response
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    formatDate,
    getDateRangeOfWeek(week, year) {
      // Create a date for 1 Jan in required year
      const d = new Date(year, 0)
      // Get day of week number, sun = 0, mon = 1, etc.
      const dayNum = d.getDay()
      // Get days to add
      let requiredDate = --week * 7

      // For ISO week numbering
      // If 1 Jan is Friday to Sunday, go to next week
      if (dayNum !== 0 || dayNum > 4) {
        requiredDate += 7
      }
      // Add required number of days
      d.setDate(1 - d.getDay() + ++requiredDate)

      const rangeIsFrom = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
      d.setDate(d.getDate() + 6)

      const rangeIsTo = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`

      return `${rangeIsFrom} / ${rangeIsTo}`
    },
    updateData(timeline) {
      this.selection = timeline
      const xlenght = Array.from(this.getSeries.x).length
      switch (timeline) {
        case 'one_month':
          this.$refs.chart.zoomX(xlenght - 5, xlenght + 1)
          break
        case 'one_year':
          this.$refs.chart.zoomX(xlenght - 52, xlenght + 1)
          break
        case 'week':
          this.$refs.chart.zoomX(xlenght - 2, xlenght + 1)
          break
        case 'all':
          this.$refs.chart.zoomX(0, xlenght + 1)
          break
        default:
      }
    },
  },
}
</script>
